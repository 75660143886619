<template>
  <HeaderBar v-on:back="moveBack" v-on:logout="$emit('logout')"/>
  <component class="component" :is="activePage" v-bind="currentProperty"
             v-on:change-page="(component) => activePage = component"
  ></component>
</template>

<script>
import sites from "@/sites.json"
import PageSelector from "@/components/main/logged-in-controller/page-selector/PageSelector";
import Iframe from "@/components/main/logged-in-controller/iframe/Iframe";
import TimeTracker from "@/components/main/logged-in-controller/time-tracker/TimeTracker";
import CreditCheck from "@/components/main/logged-in-controller/credit-check/CreditCheck";
import UserAdmin from "@/components/main/logged-in-controller/user-admin/UserAdmin";
import HeaderBar from "./HeaderBar";
import DocumentationUserManagement from "./documentation-user-management/DocumentationUserManagement";
import ServicesLogs from "./services-logs/ServicesLogs";

export default {
  name: "LoggedInController",
  emits: ['login', 'logout'],
  props: {
    permissions: Array
  },
  data() {
    return {
      activePage: 'PageSelector',
      sites: [],
    }
  },
  methods: {
    moveBack: function () {
      this.activePage = 'PageSelector'
    }
  },
  mounted() {
    const accessableSites = [];

    sites.forEach(site => {
      if (this.permissions.indexOf(site.permissionName) >= 0 || !site.permissionName) {
        accessableSites.push(site)
      }
    })
    this.sites = accessableSites;
  },
  components: {
    ServicesLogs, HeaderBar, PageSelector, Iframe, TimeTracker, CreditCheck, UserAdmin, DocumentationUserManagement
  },
  computed: {
    currentProperty: function () {
      switch (this.activePage) {
        case 'PageSelector':
          return {sites: this.sites}
        default:
          return {};
      }
    }
  }
}
</script>

<style scoped>
#form {
  max-width: 280px;
  padding-top: 50vh;
  transform: translateY(-25%);
  margin-left: auto;
  margin-right: auto;
}

#form input {
  width: 100%;
  display: block;
}

#form button {
  display: block;
  margin-top: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.component {
  height: calc(100% - 200px);
}
</style>
