<template>
  <div class="accordion" id="accordion">
    <div class="card" v-for="(module, index) of result" v-bind:key="index">
      <div class="card-header" :id="'resultScreenAccordionHeader'+index">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                  :data-target="'#'+index" aria-expanded="false" :aria-controls="index">
            {{ index }}
          </button>
        </h2>
      </div>
      <div :id="index" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body" v-html="module"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultScreenAccordion",
  props: {
    result: {}
  },
  methods: {
  }
}
</script>

<style scoped>
.accordion {
  height: 100%;
  overflow-y: auto;
}

.btn:focus {
  box-shadow: none;
}
</style>
