<template>
  <div class="row">
    <div class="col-3 px-5 mt-3" v-for="dealer in dealers" v-bind:key="dealer.id">
      <div class="col-12 p-3 bg-secondary text-white card" @click="$emit('setDealer', dealer.name, dealer.id)">
        {{ dealer.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DealersOverview",
  props: {
    dealers: Array
  },
  emits: ['setDealer'],
  setup(){

  }
}
</script>

<style scoped>

</style>
