<template>
  <div class="row">
    <div class="col-4 px-5" style="overflow-y: auto; max-height: calc(100vh - 180px)">
      <div
          class="col-12 p-3  mt-3 bg-dark text-white card"
          v-for="(vehicle, index) in vehicles"
          v-bind:key="vehicle.id"
          @click="selectedVehicle = vehicle"
      >
        <span :style="{color: Object.keys(vehicle.error_bag).length > 0 ? '#FF7777': 'white'}">
          {{ vehicle.vin ? vehicle.vin : index }}
           <span v-if="vehicle.uploadingTime" style="color: white">({{ vehicle.dms }} {{ vehicle.uploadingTime }})</span>
        </span>
      </div>
    </div>
    <div class="col-4 px-2 py-3 bg-dark text-white" style="overflow-y: auto; max-height: calc(100vh - 180px)">
      <div class="row" v-for="(value, index) in selectedVehicle.data" v-bind:key="index">
        <div class="col-6 text-right">
          <span
              :style="{color: Object.keys(selectedVehicle.error_bag).indexOf(index) > -1 ? '#FF7777': 'white'}">{{
              index
            }}:</span>
        </div>
        <div class="col-6">
          {{ value }}
        </div>
      </div>
    </div>
    <div class="col-4 px-5 py-3 bg-dark text-white" style="overflow-y: auto; max-height: calc(100vh - 180px)">
      <div class="row border-bottom my-3" v-for="(value, index) in selectedVehicle.error_bag" v-bind:key="index">
        <div class="col-6">
          {{ index }}
        </div>
        <div class="col-6">
          {{ value[0] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "DateDetailView",
  props: {
    vehicles: Array
  },
  setup() {

    const selectedVehicle = ref({});

    return {selectedVehicle};
  }
}
</script>

<style scoped>

</style>
