<template>
  <div id="myModal" class="custom-modal" v-if="showModal">
    <div class="custom-modal-content">
      <span class="close" v-on:click="close()">&times;</span>
      <h2 class="h2">Neuer Nutzer</h2>
      <hr/>
      <form class="row">
        <div class="col-6">
          <label class="col-form-label">Vorname:</label>
          <input type="text" class="form-control" v-model="firstname"/>
        </div>
        <div class="col-6">
          <label class="col-form-label">Nachname:</label>
          <input type="text" class="form-control" v-model="lastname"/>
        </div>
        <div class="col-6">
          <label class="col-form-label">Login:</label>
          <input type="text" class="form-control" v-model="login"/>
        </div>
        <div class="col-6">
          <label class="col-form-label">Passwort:</label>
          <input type="password" class="form-control" v-model="password"/>
        </div>
      </form>
      <hr/>
      <div class="row justify-content-end pl-3">
        <div class="col-12 col-lg-5">
          <div class="alert alert-success" v-if="showSuccess">Speichern Erfolgreich</div>
          <div class="alert alert-danger" v-if="showError">Speichern Fehlgeschlagen</div>
        </div>
        <div class="col-6 col-lg-4">
          <button class="btn btn-secondary btn-block" v-on:click="close">Schließen ohne zu Speichern</button>
        </div>
        <div class="col-6 col-lg-3">
          <button class="btn btn-primary btn-block" v-on:click="save">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import axios from "@/axios";

export default {
  name: "UserAdminAdduserModal",
  props: {
    showModal: String
  },
  setup(context, props) {
    const firstname = ref(''), lastname = ref(''), login = ref(''), password = ref(''), showSuccess = ref(false),
        showError = ref(false);
    function close() {
      props.emit('hideModal');
      firstname.value = '';
      lastname.value = '';
      login.value = '';
      password.value = '';
      showSuccess.value = false;
      showError.value = false;
    }

    function save() {
      const saveBag = {
        firstname: firstname.value,
        lastname: lastname.value,
        login: login.value,
        password: password.value
      };
      axios.getInstance().connection.post(`authentication/v1/register`, saveBag).then(({data}) => {
        showSuccess.value = data.success;
        showError.value = !data.success;
        props.emit('refresh')
        setTimeout(function () {
          close();
        }, 1500)
      }).catch(() => {
        showSuccess.value = false;
        showError.value = true;
      });
    }
    return {firstname, lastname, login, password, close, save, showSuccess, showError};
  }
}
</script>

<style scoped>
.custom-modal {
  position: absolute; /* Stay in place */
  display: table;
  z-index: 3000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.custom-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.alert {
  margin-bottom: 1rem;
  padding: 0.45rem 1.25rem;
  text-align: center;
}
</style>
