<template>
  <div>
    <div class="form-group row">
      <label for="contactSearch" class="col-6 col-form-label text-right col-form-label-sm">Suche</label>
      <div class="col px-0">
        <input type="text"
               class="form-control-sm form-control"
               id="contactSearch"
               placeholder="Externe Dealer id"
               v-model="searchTerm"
               v-on:focusin="display = 'block'"
               v-on:focusout="hideBox"
        />
        <div class="dropdown" :style="{display: display}">
          <div
              v-for="(dealer) in filteredObj()"
              v-bind:key="dealer.sf_id"
              v-on:click="$emit('dealer', dealer)"
          >
            {{ dealer.external_dealer_id }}
          </div>
        </div>
      </div>
    </div>
    <hr/>
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  name: "DocumentationUserManagementSearch",
  emits: ['dealer'],
  props: {dealers: Array},
  setup(props) {
    const searchTerm = ref('');
    const display = ref('none');

    function filteredObj() {
      return props.dealers.filter(dealer => dealer.external_dealer_id.includes(searchTerm.value))
    }

    function hideBox() {
      setTimeout(() => display.value = 'none', 200);
    }

    return {filteredObj, searchTerm, display, hideBox};
  },
}
</script>

<style scoped>
.dropdown {
  position: absolute;
  background: #CCCCCC;
  z-index: 200;
  padding: .5em;
  border-radius: 5px;
  border: 1px solid;
  min-height: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 32ch;
  max-height: 450px;
  width: 450px;
}

.dropdown > div {
  padding: 5px 0;
  cursor: pointer;
}

.dropdown > div:hover {
  background: #B8B8B8;
}
</style>
