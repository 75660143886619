<template>
  <div class="container-fluid p-4">
    <div class="row">
      <Card
          v-for="site of filteredSites" :key="site.id" :title="site.name" :text="site.description"
          :component="site.component"
          v-on:change-page="component => $emit('change-page', component)"
      />
    </div>
  </div>
</template>

<script>
import Card from "@/components/main/logged-in-controller/page-selector/Card";

export default {
  name: "PageSelector",
  emits: ['change-page'],
  components: {
    Card
  },
  props: {
    sites: Array
  },
  computed: {
    filteredSites: function () {
      return this.sites.filter(({showInPageSelect}) => showInPageSelect)
    }
  }
}
</script>

<style scoped>
</style>
