<template>
  <div class="form-group row">
    <label :for="name" class="col-6 col-form-label text-right col-form-label-sm">{{ label }}</label>
    <input :type="type"
           class="form-control-sm form-control col"
           v-on:change="$.emit('update:value', getValue($event))"
           :id="name"
           :value="value"
           :checked="value"
    />
  </div>
</template>

<script>
export default {
  name: "FormGroup",
  emits: ['update:value'],
  props: {
    name: String,
    label: String,
    type: String,
    value: [String, Boolean],
  },
  data() {
    return {
      formType: '',
    }
  },
  mounted() {
    this.formType = this.type
  },
  methods: {
    getValue(event) {
      return this.formType === 'checkbox' ? event.target.checked : event.target.value // eslint-disable-line
    }
  }
}
</script>

<style scoped>

</style>
