<template>
  <LoginPage v-if="! loggedIn" v-on:login="login" v-bind:loginError="displayErrorMessage"></LoginPage>
  <LoggedInController v-if="loggedIn" v-bind:permissions="permissions" v-on:logout="logout"></LoggedInController>
</template>

<script>
import LoginPage from "@/components/main/LoginPage";
import LoggedInController from "@/components/main/logged-in-controller/LoggedInController";
import axios from "@/axios";
import {onMounted, ref} from "vue";

export default {
  name: 'Main',
  emits: ['login'],
  components: {
    LoginPage,
    LoggedInController
  },
  setup() {
    const loggedIn = ref(false);
    const permissions = ref([]);
    const displayErrorMessage = ref(false);
    const axiosInstance = axios.getInstance();
    const login = function ({username, password}) {
      axiosInstance.login(username, password).then(res => {
        loggedIn.value = res.success;

        if (!loggedIn.value) return displayErrorMessage.value = true;

        permissions.value = res.permissions;
      });
    }

    const logout = function (){
      axiosInstance.logout();
      loggedIn.value = false;
      permissions.value = [];
    }

    const checkIfSessionHasLoggedInUser = () => {

      if (axiosInstance.hasInformationInStorage()) {
        const answer = axiosInstance.signInFromStorage();
        loggedIn.value = true;
        permissions.value = answer.permissions;
      }
    };

    onMounted(checkIfSessionHasLoggedInUser)

    return {loggedIn, permissions, displayErrorMessage, login, logout}
  }
}
</script>

<style scoped>

</style>
