<template>
  <div class="container-fluid content-component p-4">
    <div class="row row-cols-4">
      <UserAdminAddUserCard v-on:showModal="showAddUserModal = true"/>
      <UserAdminCard v-for="user in users.value" v-bind:key="user.id" v-bind:user="user" v-on:open-modal="openModal"/>
    </div>
  </div>
  <UserAdminModal v-if="showModal"
                  v-model:user="changeUser.value"
                  v-bind:permissions="permissions.value"
                  v-on:close="closeModal"/>
  <UserAdminAdduserModal
      v-bind:showModal="showAddUserModal"
      v-on:hideModal="showAddUserModal = false"
      v-on:refresh="updateUserAndPermissions"
  />
</template>

<script>
import axios from "@/axios";
import {reactive, ref} from 'vue';
import UserAdminCard from "@/components/main/logged-in-controller/user-admin/UserAdminCard";
import UserAdminModal from "@/components/main/logged-in-controller/user-admin/UserAdminModal";
import UserAdminAddUserCard from "@/components/main/logged-in-controller/user-admin/UserAdminAddUserCard";
import UserAdminAdduserModal from "@/components/main/logged-in-controller/user-admin/UserAdminAdduserModal";

export default {
  name: "UserAdmin",
  components: {UserAdminAdduserModal, UserAdminAddUserCard, UserAdminModal, UserAdminCard},
  setup() {
    const showAddUserModal = ref(false);
    const axiosConnection = axios.getInstance().connection;
    let users = reactive([]), permissions = reactive([]);
    function updateUserAndPermissions() {axiosConnection.get('authentication/v1/index').then(({data}) => {
      users.value = data.msg.user;
      permissions.value = data.msg.permissions;
    })}
    updateUserAndPermissions();
    const showModal = ref(false);
    const changeUser = reactive({});

    function openModal(user) {
      changeUser.value = user;
      showModal.value = true;
    }

    function closeModal(){
      updateUserAndPermissions();
      showModal.value = false;
    }

    return {users, permissions, showModal, openModal, changeUser, closeModal, updateUserAndPermissions, showAddUserModal}
  }
}
</script>

<style scoped>

</style>
