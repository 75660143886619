<template>
  <div class="col card" v-on:click="$.emit('show-modal')">
    <div class="card-body">
      +
    </div>
  </div>
</template>

<script>

export default {
  name: "UserAdminAddUserCard",
  emits: ['show-modal'],
}
</script>

<style scoped>
.card-body {
  text-align: center;
  font-size: 7rem;
}

.card {
  cursor: pointer;
  user-select: none;
}
</style>
