<template>
   <div id="form" v-on:keyup.enter="login">
      <div>
        <label>Login:</label>
        <input type="text" class="form-control form-control-sm" placeholder="Loginname" v-model="userName">
      </div>
      <div>
        <label>Passwort:</label>
        <input type="password" class="form-control form-control-sm" placeholder="Passwort" v-model="password">
      </div>
      <div>
        <button v-on:click="login" class="btn btn-sm btn-primary">Login</button>
      </div>
    </div>
  <div v-if="loginError" class="badge badge-warning p-4">
    Login fehlgeschlagen, bitte überprüfe deine Logindaten
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  emits: ['login'],
  data() {
    return {
      userName: '',
      password: '',
    }
  },
  props: {
    loginError: Boolean
  },
  methods: {
    login: function (){
      this.$emit('login', {username: this.userName, password:this.password});
    }
  }
}
</script>

<style scoped>
#form{
  max-width: 280px;
  padding-top: 50vh;
  transform: translateY(-25%);
  margin-left: auto;
  margin-right: auto;
}
#form input{
  width: 100%;
  display: block;
}
#form button{
  display: block;
  margin-top: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.badge{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -100px;
}
</style>
