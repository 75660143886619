<template>
  <div class="container-fluid">
    <div class="row py-4 h-100 pr-4">
      <div class="col-4">
        <CreditCheckForm v-on:result="updateResult"/>
      </div>
      <div class="col-2 h-100 align-self-center lightBackground" style="max-width: 200px">
        <ResultScreen v-bind:scoreAmpel="scoreAmpel"/>
      </div>
      <div class="col-6 h-100 align-self-center lightBackground">
        <ResultScreenAccordion v-bind:result="result"/>
      </div>
    </div>
  </div>
</template>

<script>
import CreditCheckForm from "@/components/main/logged-in-controller/credit-check/form/CreditCheckForm";
import ResultScreen from "@/components/main/logged-in-controller/credit-check/result-screen/ResultScreen";
import ResultScreenAccordion
  from "@/components/main/logged-in-controller/credit-check/result-screen/accordion/ResultScreenAccordion";

export default {
  name: "CreditCheck",
  components: {ResultScreenAccordion, CreditCheckForm, ResultScreen},
  data() {
    return {
      scoreAmpel: 'rot',
      result: {},
      customer: [],
    }
  },
  methods: {
    updateResult: function (res, scoreAmpel) {
      this.result = res;
      this.scoreAmpel = scoreAmpel;
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
.lightBackground {
  background: #eeeeee;
}
</style>
