<template>
  <div class="row">
    <div class="accordion col-12 px-5" id="dates">
      <div class="card" v-for="(week, index) in dates" v-bind:key="index">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse"
                    :data-target="'#collapse'+index" aria-expanded="true" :aria-controls="'collapse'+index">
              Woche: {{ week[0].date.format('WW') }}
            </button>
          </h2>
        </div>

        <div :id="'collapse'+index" class="collapse" aria-labelledby="headingOne" data-parent="#dates">
          <div class="card-body p-4">
            <div class="row mb-3" v-for="date in week" v-bind:key="date.id">
              <div class="col-12 p-3 bg-secondary text-white card" @click="$emit('setDate',date.date, date.id)">
                <div class="d-inline">
                  {{ date.date.format('DD.MM.YYYY H:m:s') }}
                  <div class="badge badge-warning" style="max-width: 150px">{{ date.type }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatesOverview",
  props:{
    dates: Array
  },
  emits: ['setDate']
}
</script>

<style scoped>

</style>
