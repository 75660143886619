<template>
  <div>
    <div class="form-group row">
      <label for="contactSearch" class="col-6 col-form-label text-right col-form-label-sm">Suche</label>
      <div class="col">
        <input type="text"
               class="form-control-sm form-control"
               id="contactSearch"
               placeholder="beispiel@email.de"
               v-model="searchTerm"
               v-on:focusin="display = 'block'"
               v-on:focusout="hideBox"
        />
        <div class="dropdown" :style="{display: display}">
          <div
              v-for="(address, index) in filteredObj()"
              v-bind:key="index"
              v-on:click="emitContact(address)"
          >
            {{ address.email }}
          </div>
        </div>
      </div>
    </div>
    <hr/>
  </div>
</template>

<script>
import axios from "@/axios";
import {ref} from 'vue';

export default {
  name: "CreditCheckSearch",
  emits: ['contact'],
  setup(bag, context) {
    let contacts = [];
    const searchTerm = ref('');
    const display = ref('none');
    axios.getInstance().connection
        .get('/credit-check/v1/salesforce-customer')
        .then(({data}) => {
          if (data.length) {
            contacts = data;
          }
        }).catch(() => {
    })

    function filteredObj() {
      return contacts.filter(contact => contact.email.includes(searchTerm.value))
    }
    function emitContact(address) {
      context.emit('contact', address);
    }

    function hideBox(){
      setTimeout(() => display.value = 'none', 200);
    }

    return {filteredObj, searchTerm, display, emitContact, hideBox};
  },
}
</script>

<style scoped>
.dropdown {
  position: absolute;
  background: #CCCCCC;
  z-index: 200;
  padding: .5em;
  border-radius: 5px;
  border: 1px solid;
  min-height: 50px;
  overflow-y:auto;
  overflow-x: hidden;
  max-width: 32ch;
  max-height: 450px;
}

.dropdown > div {
  padding: 5px 0;
  cursor: pointer;
}

.dropdown > div:hover {
  background: #B8B8B8;
}
</style>
