<template>
  <div v-if="selectedDealer[0]" class="row">
    <div class="col-6 text-right">
      Hauptdealer:
    </div>
    <div class="col-6">
      {{ selectedDealer[0].external_dealer_id }}
    </div>
  </div>
  <div v-if="childDealer.length">
    <div v-for="(dealer, index) in childDealer" class="row" v-bind:key="index">
      <div class="col-6 text-right">
        Unterdealer {{ index + 1 }}:
      </div>
      <div class="col-6">
        {{ dealer.external_dealer_id }}
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "DocumentationUserManagementSelectedDealer",
  props: ['selectedDealer'],
  computed: {
    childDealer: function(){return  this.selectedDealer.filter((dealer, index) => index >= 1)}
  }
}
</script>

<style scoped>

</style>
