<template>
  <div class="row">
    <button class="ml-auto btn-primary btn btn-sm" type="button" v-on:click="$emit('clicked');">Überprüfen</button>
  </div>
</template>

<script>
export default {
  name: "SendButton",
  emits: ['clicked']
}
</script>

<style scoped>

</style>
