<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-2 p-2">
        <input type="text" class="form-control form-control-sm" placeholder="Suche..." v-model="searchTerm">
        <div class="accordion" id="accordion">
          <Client v-for="client of clients" v-bind:key="client.id" :client="client" v-bind:searchTerm="searchTerm"/>
        </div>
      </div>
      <div class="col-10">
        <div class="row h-16">
          <div class="col-12">bar</div>
        </div>
        <div class="row h-84">
          <div class="col-12">content</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from '@/axios';
import Client from "@/components/main/logged-in-controller/iframe/client/Client";

const axios = Axios.getInstance();
export default {
  name: "Iframe",
  components: {Client},
  data() {
    return {
      searchTerm: '',
      clients: []
    }
  },
  mounted() {
    axios.connection.get('/iframe/v1').then(res => {
      this.clients = res.data.msg.clients;
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>
.h-16{
  height: 16.66%;
}
.h-84{
  height: 83.33%;
  background: white;
}
</style>
