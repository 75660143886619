<template>
  <div class="col card">
      <div class="card-body" v-on:click="$emit('open-modal', user)">
        <h5 class="card-title">{{ user.name }}</h5>
        <hr/>
        <h6 class="card-subtitle mb-2 text-muted" v-if="user.access.includes('user-admin')">Ist User
          Administrator</h6>
        <ul style="padding-left: 1rem">
          <li v-for="(access, index) in user.access" v-bind:key="index">{{ access }}</li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAdminCard",
  props: {user: Object},
  emits: ['open-modal']
}
</script>

<style scoped>
.card {
  user-select: none;
  cursor: pointer;
}
</style>