<template>
  <div class="col-6 col-lg-4 mb-4">
    <div class="card" v-on:click="$emit('change-page', component)">
      <div class="card-body">
        <h3 class="card-title">{{ title }}</h3>
        <p class="card-text">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  emits: ['change-page'],
  props: {
    title: String,
    text: String,
    component: String
  }
}
</script>

<style scoped>
.card {
  user-select: none;
  cursor: pointer;
}
</style>
