<template>
  <div class="container-fluid">
    <div class="row">
      <div class="pl-0 col-2">
        <button class="btn btn-block btn-secondary" v-on:click="$emit('back')">Zurück</button>
      </div>
      <div class="col-8" id="newWrapper">
        <transition-group name="move">
        </transition-group>
      </div>
      <div class="col-2 pr-0">
        <button class="btn btn-block btn-secondary" v-on:click="$emit('logout')">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";

export default {
  name: "HeaderBar",
  emits: ['logout', 'back'],
  setup() {
    const show=ref(false);
    // setInterval(() => show.value = !show.value, 15000);

    return {show};
  }
}
</script>

<style scoped>
.row {
  color: #fff;
  background: linear-gradient(#111, #000);
}

#newWrapper {
  font-family: "Arial Rounded MT Bold", Arial, sans-serif;
  font-size: 20px;
  font-weight: bolder;
  line-height: 38px;
  overflow: hidden;
}

#newWrapper > div {

  display: inline-flex;
  margin-left: 50px;
}

.move-enter-active{
  animation: moveInformations 10s infinite linear;
}
@keyframes moveInformations {
  from {
    transform: translateX(100vw)
  }
  to {
    transform: translateX(0)
  }
}
</style>
