<template>
  <div></div>
<!--  <li class="uk-parent">-->
<!--    <a href="#">{{client.client}}</a>-->
<!--    <ul class="uk-nav-sub iframe-client-dealer">-->
<!--      <li v-for="dealer of filteredDealers" v-bind:key="dealer.id">-->
<!--        <a href="#">{{dealer.sf_dealer_name}}</a>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </li>-->
</template>

<script>
export default {
  name: "Client",
  props: {
    searchTerm: String,
    client: {client: String, dealers: [{id: Number, sf_dealer_name: String, sf_dealer_id: String}]}
  },
  computed: {
    filteredDealers(){
      return this.client.dealers.filter(dealer =>
          dealer.sf_dealer_name.toLowerCase().includes(this.searchTerm.toLowerCase())
          || dealer.sf_dealer_id.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    }
  }
}
</script>

<style scoped>
.uk-parent{
  height: 100%;
}
.iframe-client-dealer {
  height: calc(100% - 10px);
  max-height: calc(100% - 10px);
  overflow-y: auto;
  overflow-x: hidden;
}

.iframe-client-dealer::-webkit-scrollbar {
  width: 3px;
}

.iframe-client-dealer::-webkit-scrollbar-track {
  background: black;
}

.iframe-client-dealer::-webkit-scrollbar-thumb {
  background: white;
}
</style>
