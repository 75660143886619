<template>
  <div class="container-fluid p-5">
    <div class="row">
      <div class="col-12 col-md-9 col-lg-5 pr-5">
        <div class="row">
          <div class="col-12 text-center">
            <h1>Neuen Nutzer anlegen</h1>
          </div>
        </div>
        <div class="row" v-if="errorBag.length">
          <div class="alert alert-danger col-12" role="alert">
            <ul>
              <li v-for="(error, index) of errorBag" :key="index">
                {{ error[0] }}
              </li>
            </ul>
          </div>
        </div>
        <form-group label="Name" name="name" type="text" v-model:value="createDealerPayload.name"/>
        <form-group label="Passwort" name="password" type="password" v-model:value="createDealerPayload.password"/>
        <div class="form-group row">
          <label for="selectUrl" class="col-6 col-form-label text-right col-form-label-sm">Url</label>
          <div class="input-group col-6 px-0">
            <select class="form-control-sm w-100" id="selectUrl" v-model="createDealerPayload.store_view_url">
              <option v-for="(url, index) in storeViewUrls" v-bind:key="index"
                      :value="url"
              >{{ url }}
              </option>
            </select>
          </div>
        </div>
        <DocumentationUserManagementSearch :dealers="dealers" v-on:dealer="addSelectedDealer"/>
        <div class="row">
          <div class="col-12 justify-content-end px-0">
            <button class="btn btn-primary d-block ml-auto" @click="createNewDealer">Speichern</button>
          </div>
        </div>
        <DocumentationUserManagementSelectedDealer :selectedDealer="selectedDealer"/>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12">
            <h1>Aktive Nutzer</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="max-height: calc(100vh - 150px); overflow: auto">
            <table class="table table-striped text-center">
              <thead class="thead-dark">
              <tr>
                <th scope="col" style="width: 10%">#</th>
                <th scope="col" style="width: 42.5%">Name</th>
                <th scope="col" style="width: 42.5%">Storeview url</th>
                <th scope="col" style="width: 5%"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(documentationUser, index) in documentationUsers " v-bind:key="documentationUser.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ documentationUser.name }}</td>
                <td>{{ documentationUser.store_view_url }}</td>
                <td @click="deleteDealer(documentationUser.id)" title="Delete Aktive Nutzer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
                       viewBox="0 0 16 16">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../../axios";
import {reactive, ref} from "vue";
import FormGroup from "../shared/FormGroup";
import DocumentationUserManagementSearch from "./form/DocumentationUserManagementSearch";
import DocumentationUserManagementSelectedDealer from "./form/DocumentationUserManagementSelectedDealer";

function CreateDealerPayload() {
  this.name = '';
  this.password = '';
  this.store_view_url = '';
  this.main_dealer_sf_account_id = '';
  this.sub_Dealer_sf_account_ids = [];
}

export default {
  name: "DocumentationUserManagement",
  components: {DocumentationUserManagementSelectedDealer, DocumentationUserManagementSearch, FormGroup},
  setup() {
    const axiosInstance = axios.getInstance();
    const storeViewUrls = ref([]);
    const documentationUsers = ref([]);
    const dealers = ref([]);
    const selectedDealer = ref([]);
    const errorBag = ref([]);
    const baseUrl = '/documentation-user-management/v1';
    const createDealerPayload = reactive(new CreateDealerPayload());
    axiosInstance.connection.get(baseUrl).then(({data: {msg}}) => {
      storeViewUrls.value = msg.storeViewUrls;
      createDealerPayload.store_view_url = storeViewUrls.value[0];
      documentationUsers.value = msg.documentationUsers;
      dealers.value = msg.dealer;
    })

    function addSelectedDealer(dealer) {
      const arrayBetween = selectedDealer.value;
      arrayBetween.push(dealer)
      selectedDealer.value = arrayBetween;
    }

    function clearDealerPayload() {
      createDealerPayload.store_view_url = storeViewUrls.value[0];
      createDealerPayload.main_dealer_sf_account_id = '';
      createDealerPayload.name = '';
      createDealerPayload.password = '';
      createDealerPayload.sub_Dealer_sf_account_ids = [];
      selectedDealer.value = [];
      errorBag.value = [];
    }

    function createError(msg) {
      errorBag.value = Object.values(msg);
    }

    function createNewDealer() {
      const copySelectedDealer = JSON.parse(JSON.stringify(selectedDealer.value)).map(({sf_id}) => sf_id);
      createDealerPayload.main_dealer_sf_account_id = copySelectedDealer.shift();
      createDealerPayload.sub_Dealer_sf_account_ids = copySelectedDealer;
      axiosInstance.connection.post(baseUrl, createDealerPayload).then(({data: {msg}}) => {
        documentationUsers.value.push(msg);
        clearDealerPayload();
      }).catch(({response: {data: {msg}}}) => {
        createError(msg);
      })
    }

    function deleteDealer(id) {
      axiosInstance.connection.delete(baseUrl + '/' + id,).then(({data}) => {
        if (data.success) {
          documentationUsers.value = documentationUsers.value.filter(x => {
            return x.id != id;
          });
        }
      }).catch(({response: {data: {msg}}}) => {
        createError(msg);
      })
    }

    return {
      storeViewUrls,
      documentationUsers,
      dealers,
      addSelectedDealer,
      selectedDealer,
      createDealerPayload,
      createNewDealer,
      errorBag,
      deleteDealer
    };
  }
}
</script>

<style scoped>
td > svg {
  width: 22px;
  height: auto;
}

td > svg:hover {
  color: rgb(255, 0, 0);
  cursor: pointer;
}

</style>