<template>
  <div class="row">
    <div class="col-4 mt-3" v-for="(dms) in dmss" v-bind:key="dms.id">
      <div class="row px-5 h-100">
        <div class="col-12 p-3 bg-secondary text-white card" @click="$emit('setDms', dms.name, dms.id)">
          <div class="row">
            <div class="col-12">
              <h4>{{ dms.name }}</h4>
              <hr style="border-color: #fff">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul>
                <li v-for="(dealer,index) of dms.dealer" v-bind:key="index">{{ dealer }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DmsOverview",
  props: {
    dmss: Array
  },
  emits: ['setDms'],
}
</script>

<style scoped>

</style>
