import axios from 'axios';
const {env} = require('./environment.js')
const baseurl = env.apiUrl+'/api';

let instance = null;

function Axios() {
    this.connection = undefined;
    this.permissions = undefined;
    this.statisticServerToken = undefined;

    this.loggedIn = () => {
        return !!this.connection;
    }

    this.login = (name, password) => {
        return axios.post(
            `${baseurl}/authentication/v1/login`,
            {username: name, password: password})
            .then(({data}) => {
                if (data.success) {
                    this.setupConnection(data.msg);
                    this.saveLoginResponse(data.msg);
                    return {success: true, permissions: this.permissions};
                }
                return {success: false};
            }).catch(() => {
                this.connection = null;
                return {success: false};
            })
    }

    this.setupConnection = (loggedInResponse) => {
        this.connection = axios.create({
            baseURL: baseurl,
        });
        this.connection.defaults.headers.common['Authorization'] = `Bearer ${loggedInResponse.auth_token}`;
        this.permissions = loggedInResponse.access;

        //save statistic token in this class
    }

    this.saveLoginResponse = (loggedInResponse) => {
        window.sessionStorage.setItem('message', JSON.stringify(loggedInResponse));
    }

    this.signInFromStorage = () => {
        this.setupConnection(this.getInformationOutOfStorage());
        return {permissions: this.permissions}
    }

    this.getInformationOutOfStorage = function () {
        const message = window.sessionStorage.getItem('message');
        if (message) {
            return JSON.parse(message);
        }
        return '';
    }

    this.hasInformationInStorage = function (){
        return !!this.getInformationOutOfStorage();
    }

    this.logout = function (){
        window.sessionStorage.clear();
    }

    //make your request
}

const getInstance = () => {
    if (!instance) {
        instance = new Axios();
    }
    return instance;
}

export default {getInstance: getInstance}
