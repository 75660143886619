<template>
  <form class="px-4" v-on:keyup.enter="makeRequest">
    <h2 class="h2 text-center border-bottom border-dark">Credit Check</h2>
    <CreditCheckSearch v-on:contact="setContact"/>
    <FormGroup name="b2c" label="B2C:" type="checkbox" v-model:value="b2c"/>
    <FormGroup name="company" label="Firmenname:" type="text" v-model:value="company" v-if="!b2c"/>
    <FormGroup name="firstname" label="Vorname:" type="text" v-model:value="firstname"/>
    <FormGroup name="lastname" label="Nachname:" type="text" v-model:value="lastname"/>
    <FormGroup name="birthday" label="Geburtstag:" type="text" v-model:value="birthday"/>
    <FormGroup name="street" label="Straße:" type="text" v-model:value="street"/>
    <FormGroup name="house_nummer" label="Hausnummer:" type="text" v-model:value="house_nummer"/>
    <FormGroup name="zip" label="Postleitzahl:" type="text" v-model:value="zip"/>
    <FormGroup name="city" label="Stadt:" type="text" v-model:value="city"/>
    <SendButton v-on:clicked="makeRequest"/>
  </form>
</template>

<script>
import FormGroup from "@/components/main/logged-in-controller/shared/FormGroup";
import SendButton from "@/components/main/logged-in-controller/credit-check/form/SendButton";
import CreditCheckSearch from "@/components/main/logged-in-controller/credit-check/form/CreditCheckSearch";
import axios from "@/axios";

export default {
  name: "CreditCheckForm",
  emits: ['result'],
  components: {FormGroup, SendButton, CreditCheckSearch},
  data() {
    return {
      firstname: '',
      lastname: '',
      birthday: '',
      street: '',
      house_nummer: '',
      zip: '',
      city: '',
      company: '',
      b2c: true
    }
  },
  mounted() {
    const date = new Date();
    this.birthday = `${date.getDay()}.${date.getMonth()}.${date.getFullYear() - 18}`
  },
  methods: {
    makeRequest: function () {
      axios.getInstance().connection
          .post('/credit-check/v1/check', {
            firstname: this.$data.firstname,
            lastname: this.$data.lastname,
            birthday: this.getBirthday(),
            street: this.$data.street,
            house_nummer: this.$data.house_nummer,
            zip: this.$data.zip,
            city: this.$data.city,
            company_name: this.$data.b2c ? '' : this.$data.company,
            b2c: this.$data.b2c
          }).then(({data}) => {
        const newmsg = {};
          for(let val in data.msg){
            if(val !== 'xml')
            newmsg[val] = JSON.stringify(data.msg[val]);
          }
          this.$emit('result', newmsg, data.msg.ScoreModul.Scores.Score.ampel)
      }).catch(({response: {data}}) => {
        if (data.msg) {
          return this.$emit('result', data.msg, data.msg.ScoreModul.Scores.Score.ampel)
        }
        this.$emit('result', {'Fehlermeldung': 'Unbekannter Fehler aufgetreten'})
      })
    },
    getBirthday: function () {
      return this.birthday.split('.').reverse().join('-')
    },
    setContact: function (contactObj) {
      this.resetValues();
      for (let val in contactObj) {
        if (val in contactObj && val in this.$data) {
          this.$data[val] = contactObj[val];
        }
      }
    },
    resetValues: function () {
      this.$data.firstname = '';
      this.$data.lastname = '';
      this.$data.birthday = '';
      this.$data.street = '';
      this.$data.house_nummer = '';
      this.$data.zip = '';
      this.$data.city = '';
      this.$data.company = '';
      this.$data.b2c = true;
    }
  }
}
</script>

<style scoped>

</style>
