<template>
  <div class="container-fluid content-component">
    <div class="row">
      <div class="col-10">
        <h2>
          <a @click="selectedDms = '';selectedDealer = ''; selectedDate = ''; searchTerm = ''">ViveLaLogster</a>
          <span v-if="selectedDms"> > <a @click="selectedDealer = ''; selectedDate = ''">{{ selectedDms }}</a></span>
          <span v-if="selectedDealer"> > <a @click="selectedDate = ''">{{ selectedDealer }}</a></span>
          <span v-if="selectedDate"> > {{ selectedDate }}</span>
        </h2>
      </div>
      <div class="col-2">
        <div class="input-group">
          <input type="text" class="form-control" v-model="searchTerm">
          <div class="input-group-append">
            <button class="btn btn-secondary" @click="searchVehicle">Suchen</button>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative row">
      <transition name="swipe">
        <section id="dealerSelection" v-if="!selectedDms && !selectedDealer && !selectedDate" class="col-12">
          <DmsOverview :dmss="filteredDmss()" v-on:setDms="setDms"/>
         </section>
      </transition>
      <transition name="swipe">
        <section v-if="selectedDms && !selectedDealer && !selectedDate" class="col-12">
          <DealersOverview :dealers="filteredDealer()" v-on:setDealer="setDealer"/>
        </section>
      </transition>
      <transition name="swipe">
        <section v-if="selectedDealer && !selectedDate" class="col-12">
          <DatesOverview :dates="dates" v-on:setDate="setDate"/>
        </section>
      </transition>
      <transition name="swipe">
        <section v-if="selectedDate" class="col-12">
          <DateDetailView :vehicles="vehicles"/>
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import {ref} from "vue";
import moment from "moment";
import DmsOverview from "@/components/main/logged-in-controller/services-logs/DmsOverview";
import DealersOverview from "@/components/main/logged-in-controller/services-logs/DealersOverview";
import DatesOverview from "@/components/main/logged-in-controller/services-logs/DatesOverview";
import DateDetailView from "@/components/main/logged-in-controller/services-logs/DateDetailView";

export default {
  name: "ServicesLogs",
  components: {DateDetailView, DatesOverview, DealersOverview, DmsOverview},
  setup() {
    const selectedDms = ref('');
    const selectedDealer = ref('');
    const selectedDate = ref('');
    const dealers = ref([]);
    const dates = ref([]);
    const vehicles = ref([]);
    const searchTerm = ref('');
    const dmss = ref([]);
    axios.getInstance().connection.get('/logger/v1/dms').then(res => {
      dmss.value = res.data;
    });

    function setDms(name, id) {
      axios.getInstance().connection.get(`/logger/v1/dealer/${id}`).then(res => {
        dealers.value = res.data;
        selectedDms.value = name;
      })
    }

    function setDealer(name, id) {
      axios.getInstance().connection.get(`/logger/v1/dates/${id}`).then(res => {
        let unformattedDates = res.data;
        dates.value = formatDates(unformattedDates);
        selectedDealer.value = name;
      })
    }

    function setDate(date, id) {
      axios.getInstance().connection.get(`/logger/v1/date/${id}`).then(res => {
        vehicles.value = res.data.map(mapVehicles);
        selectedDate.value = date.format('DD.MM.YYYY HH:MM:SS');
      })
    }

    function formatDates(unformattedDates) {
      if (!unformattedDates.length) {
        return [];
      }
      const resultArray = {};
      unformattedDates.forEach(date => {
        date.date = moment(date.date);
        const week = date.date.format('WW');
        if (!resultArray[week]) {
          resultArray[week] = [];
        }
        resultArray[week].push(date);
      })
      return Object.values(resultArray).sort((a, b) => {
        return b[0].date.format('WW') - a[0].date.format('WW')
      });
    }

    function filteredDmss() {
      if (!searchTerm.value) {
        return dmss.value;
      }

      return dmss.value.filter(dms => {
        dms.dealers = Object.values(dms.dealer).filter(dealer => dealer.toLowerCase().includes(searchTerm.value.toLowerCase()));
        return dms.dealers.length;
      });
    }

    function filteredDealer() {
      if (!searchTerm.value) {
        return dealers.value;
      }

      return dealers.value.filter(dealer => {
        return dealer.name.toLowerCase().includes(searchTerm.value.toLowerCase());
      });
    }

    function searchVehicle(){
      if(!searchTerm.value) return;

      axios.getInstance().connection.get(`/logger/v1/vin/${searchTerm.value}`).then(res => {
        vehicles.value = res.data.map(mapVehicles);
        selectedDate.value = searchTerm.value;
      })
    }

    function mapVehicles(vehicle){
      vehicle.data = JSON.parse(vehicle.data);
      vehicle.error_bag = JSON.parse(vehicle.error_bag);
      return vehicle;
    }

    return {
      filteredDmss,
      selectedDms,
      setDms,
      filteredDealer,
      setDealer,
      selectedDealer,
      dates,
      selectedDate,
      setDate,
      vehicles,
      searchTerm,
      searchVehicle
    };
  }
}
</script>

<style scoped>

/deep/ a {
  transition: all 500ms;
  text-decoration: underline !important;
}

/deep/ a:hover {
  text-decoration: none !important;
}

/deep/ .card, a {
  cursor: pointer;
  user-select: none;
}

/deep/ .card:hover {
  opacity: 0.9;
}

section {
  position: absolute;
  top: 0;
}

.swipe-enter-active {
  animation: swipe-in 0.5s;
}

.swipe-leave-active {
  animation: swipe-out 0.5s;
}

@keyframes swipe-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes swipe-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
