<template>
  <Main/>
</template>

<script>
require('./assets/js/bootstrap.bundle.min')
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main,
  },
}

</script>

<style>
@import './assets/css/bootstrap.min.css';

body {
  margin: 0;
}
.content-component{
  height: calc(100% - 38px - 2em) !important;
  padding: 2em;
  overflow: auto;
}
#app {
  height: 100vh;
  width: 100vw;
  background: #dddddd;
}
</style>
