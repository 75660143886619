<template>
  <div id="traffic-light">
    <div id="stopLight" class="bulb" :class="{active: color==='rot'}"></div>
    <div id="slowLight" class="bulb" :class="{active: color==='gelb'}"></div>
    <div id="goLight" class="bulb" :class="{active: color==='grün'}"></div>
  </div>
</template>

<script>
export default {
  name: "TrafficLight",
  props:{
    color: String
  }
}
</script>

<style scoped>
#traffic-light {
  height: auto;
  width: 100%;
  float: left;
  background-color: #333;
  border-radius: 40px;
  margin: auto;
  padding: 20px;
}

.bulb {
  border-radius: 50%;
  margin: 1rem auto;
  transition: background 500ms;
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  background: black;
}
#stopLight.active{
  background: red;
}
#slowLight.active{
  background: yellow;
}
#goLight.active{
  background: green;
}
</style>
