<template>
  <div class="row h-100">
    <div class="col-12 align-self-center">
      <TrafficLight v-bind:color="scoreAmpel"/>
    </div>
  </div>
</template>

<script>
import TrafficLight from "@/components/main/logged-in-controller/credit-check/result-screen/TrafficLight";

export default {
  name: "ResultScreen",
  components: {
    TrafficLight
  },
  props: {
    scoreAmpel: String
  }
}
</script>

<style scoped>
</style>
