<template>
  <div id="myModal" class="custom-modal">
    <div class="custom-modal-content">
      <div class="row">
        <div class="col-10">
          <h2 class="h2">{{ user.name }}</h2>
        </div>
        <div class="col">
          <span class="close" v-on:click="$emit('close')">&times;</span>
        </div>
      </div>
      <hr/>
      <form class="row">
        <div class="col-6" v-for="permission in permissions" v-bind:key="permission.id">
          <div class="row mb-3 text-center">
            <div class="col-6"><label class="col-form-label-sm" :for="permission.id">{{ permission.name }}</label></div>
            <input :id="permission.name+permission.id" :name="permission.name" class="form-control form-control-sm col"
                   type="checkbox"
                   :checked="user.access.includes(permission.name)">
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-4 text-right col-form-label">
          Passwort:
        </div>
        <div class="col">
          <input type="password" class="form-control" v-model="password">
        </div>
      </div>
      <hr/>
      <div class="row justify-content-end pl-3">
        <div class="col-12 col-lg-5">
          <div class="alert alert-success" v-if="showSuccess">Speichern Erfolgreich</div>
          <div class="alert alert-danger" v-if="showError">Speichern Fehlgeschlagen</div>
        </div>
        <div class="col-4 col-lg-2">
          <button class="btn btn-block btn-danger" @click="remove">Löschen</button>
        </div>
        <div class="col-6 col-lg-3">
          <button class="btn btn-secondary btn-block" v-on:click="$emit('close')">Schließen ohne zu Speichern</button>
        </div>
        <div class="col-6 col-lg-2">
          <button class="btn btn-primary btn-block" v-on:click="save">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import {ref} from 'vue';

export default {
  name: "UserAdminModal",
  emits: ['close'],
  props: ['permissions', 'user'],
  setup(props, context) {
    const showSuccess = ref(false);
    const showError = ref(false);
    const password = ref('');

    function save() {
      const saveBag = {};
      props.permissions.forEach(permission => {
        const element = document.getElementById(permission.name + permission.id);
        saveBag[permission.id] = element.checked;
      })

      saveBag.password = password.value

      axios.getInstance().connection.post(`authentication/v1/change/${props.user.id}`, saveBag).then(({data}) => {
        showSuccess.value = data.success;
        showError.value = !data.success;
        setTimeout(function () {
          context.emit('close')
        }, 1500)
      }).catch(() => {
        showSuccess.value = false;
        showError.value = true;
      });
    }

    function remove() {
      axios.getInstance().connection.delete(`authentication/v1/${props.user.id}`).then(({data}) => {
        showSuccess.value = data.success;
        showError.value = !data.success;
        setTimeout(function () {
          context.emit('close')
        }, 1500)
      }).catch(() => {
        showSuccess.value = false;
        showError.value = true;
      })
    }

    return {save, showSuccess, showError, password, remove}
  }
}
</script>

<style scoped>
.alert {
  margin-bottom: 1rem;
  padding: 0.45rem 1.25rem;
  text-align: center;
}

.custom-modal {
  display: table;
  position: absolute; /* Stay in place */
  z-index: 3000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.custom-modal-content {
  position: absolute;
  background-color: #fefefe;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
